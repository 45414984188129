import { useState } from 'react';
import styled from 'styled-components';

import { X } from './NakamotoReopeningBanner/X';

const Container = styled('div')`
  display: flex;
  background-color: #6e54cb;
  border-radius: 12px;
`;

const Container2 = styled('div')`
  max-width: 1600px;
  padding: 24px;
`;

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Headline = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const HeadlineTextContainer = styled('div')`
  font-family: DMSans-Regular;
  font-size: 21px;
  font-weight: 500;
  line-height: 26.25px;

  @media (max-width: 850px) {
    font-size: 18px;
  }
`;

const Info = styled('div')`
  padding-bottom: 24px;

  p {
    color: #fff;
    font-family: DMSans-Regular;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const GoToNewAppButton = styled.a((props) => ({
  ...props.theme.body_bold_m,
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 16px',
  color: props.theme.colors.bg.elevation0,
  background: props.theme.colors.white,
  borderRadius: 12,
  transition: 'opacity 0.1s ease',
  ':hover': {
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.6,
  },
  '@media (max-width: 850px)': {
    width: '100%',
  },
}));

export function NewStackingAppBanner() {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) return null;

  return (
    <Container>
      <Container2>
        <ContentContainer>
          <Headline>
            <HeadlineTextContainer>We’ve Upgraded!</HeadlineTextContainer>
            <div
              onClick={() => setIsOpen(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsOpen(false);
                }
              }}
              role="button"
              tabIndex={0}
              style={{ cursor: 'pointer' }}
            >
              <X />
            </div>
          </Headline>
          <Info>
            <p>
              Your currently stacked funds are safe and remain securely stacked. However, we
              recommend managing your stacking activity on our new app for an improved experience,
              revamped flow, and seamless management of Xverse commissions.
            </p>
          </Info>
          <div>
            <GoToNewAppButton
              href="https://wallet.xverse.app/earn/stacking"
              target="_blank"
              rel="noopener noreferrer"
            >
              Switch to the new App
            </GoToNewAppButton>
          </div>
        </ContentContainer>
      </Container2>
    </Container>
  );
}

import { useNavigate } from 'react-router-dom';
import BitcoinVector from '../../assets/images/bitcoin.svg';
import OrangeArrowRight from '../../assets/images/ArrowRightOrange.svg';
import ArrowRight from '../../assets/images/ArrowRight.svg';
import MobileVector from '../../assets/images/DeviceMobile.svg';
import PlayStoreBadge from '../../assets/images/playstore.svg';
import AppStoreBadge from '../../assets/images/appstore.svg';
import { playStoreUrl, appStoreUrl, xverseUrl, xverseBlog } from '../../utils/url.constants';
import { AppDownloadImageButton, RedirectButton } from '../../components/styled/Button';
import { RowFlex } from '../../components/styled/Flex';
import { HeadingText, LandingBodyText, StxLockedTitleText } from '../../components/styled/Text';
import { useContext } from 'react';
import { Container, RowContainer } from '../../components/styled/Container';
import { useConnect } from '@stacks/connect-react';
import { LoginContext } from '../../Context/LoginState';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { CycleInfoCard } from './CycleInfoCard/CycleInfoCard';
import useStackingState from '../../hooks/useStackingState';
import StackingStatusView from './StackingStatusView';
import { CycleStats } from './CycleStats';
import { useCycleData } from '../../hooks/useCycleData';
import { PoolPerks } from './PoolPerks';
import { DownloadWallet } from './DownloadWallet';
import usePoolCycleData from '../../hooks/useCycleStartData';
import useCycleBtcReward from '../../hooks/useCycleBtcReward';
import { StackingState } from '../../utils/constants';
import { NewStackingAppBanner } from '../../components/NewStackingAppBanner';

const Row = styled(RowContainer)`
  padding-block-start: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 32px;
`;

const ColumnContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginTop: 32,
  marginBottom: 40,
});

const Gradient = styled.div({
  background: '#7B81D9',
  filter: 'blur(185.294px)',
  transform: 'matrix(-0.99, 0.17, 0.17, 0.99, 0, 0)',
  width: 80,
  height: 300,
  position: 'absolute',
  right: 50,
  top: 200,
});

const LowerGradient = styled.div({
  background: '#7B81D9',
  filter: 'blur(185.294px)',
  transform: 'matrix(-0.99, 0.17, 0.17, 0.99, 0, 0)',
  width: 500,
  height: 500,
  position: 'absolute',
  left: -500,
  top: 1200,
});

const LandingContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
});

const CycleInfoCardContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
});

const PlayStoreButtonContainer = styled.div({
  marginTop: 12,
});

const ButtonContainer = styled.div({
  marginTop: 32,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const StackingInfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 200,
  paddingRight: 200,
  '@media (max-width: 900px)': {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const TransparentButton = styled.button((props) => ({
  ...props.theme.body_bold_l,
  display: 'flex',
  marginLeft: 16,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: 240,
  height: 64,
  color: props.theme.colors.white,
  border: '1px solid rgba(255, 255, 255, 0.4)',
  background: 'transparent',
  borderRadius: 8,
  transition: 'opacity 0.1s ease',
  ':hover': {
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.6,
  },
}));

const StackingButton = styled.button((props) => ({
  ...props.theme.body_bold_l,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: 240,
  color: props.theme.colors.bg.elevation0,
  height: 64,
  background: props.theme.colors.white,
  borderRadius: 8,
  transition: 'opacity 0.1s ease',
  ':hover': {
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.6,
  },
}));

const Image = styled.img({
  marginRight: 5,
});

interface Props {
  isMobile: boolean;
}

export const Landing: React.FC<Props> = ({ isMobile }) => {
  const navigate = useNavigate();
  const { userData } = useContext(LoginContext);
  const address = userData?.profile.stxAddress.mainnet;
  const { stackingStatus, stackingData } = useStackingState(address);
  const { progressPercent, nextCycleEndsIn, currCycleEndsIn, poolCloseIn } = useCycleData(
    stackingStatus,
    address,
    stackingData,
  );
  const { totalReward } = useCycleBtcReward();
  const { stxLocked, currCycleBegan } = usePoolCycleData(stackingData);
  const { doOpenAuth } = useConnect();

  function appStoreLink() {
    window.open(appStoreUrl, '_blank', 'noopener,noreferrer');
  }
  function playStoreLink() {
    window.open(playStoreUrl, '_blank', 'noopener,noreferrer');
  }
  function redirectToXverseApp() {
    window.open(xverseUrl, '_blank', 'noopener,noreferrer');
  }

  function redirectToStack() {
    window.open(xverseBlog, '_blank', 'noopener,noreferrer');
  }

  const renderDownloadApp = (
    <ColumnContainer>
      <AppDownloadImageButton onClick={() => appStoreLink()}>
        <img alt="app store" src={AppStoreBadge} width={162} height={46} />
      </AppDownloadImageButton>
      <PlayStoreButtonContainer>
        <AppDownloadImageButton marginTop={{ s: 10, md: 0, lg: 0 }} onClick={() => playStoreLink()}>
          <img alt="playstore" src={PlayStoreBadge} width={142} height={46} />
        </AppDownloadImageButton>
      </PlayStoreButtonContainer>
    </ColumnContainer>
  );

  const renderContent = (
    <Row
      flexDirection={{ s: 'column', md: 'column', lg: 'row' }}
      marginBottom={{ s: 64, md: 64, lg: 156 }}
    >
      <LandingContainer>
        <NumberFormat
          value={stxLocked}
          displayType="text"
          thousandSeparator
          suffix=" STX"
          renderText={(value: string) => (
            <StxLockedTitleText fontSize={{ s: 32, md: 52, lg: 52 }}>{value}</StxLockedTitleText>
          )}
        />

        <StxLockedTitleText fontSize={{ s: 32, md: 52, lg: 52 }}>
          stacked and earning Bitcoin
        </StxLockedTitleText>
        <LandingBodyText color="grey" width={{ lg: 600 }} marginTop={{ s: 3, md: 15, lg: 32 }}>
          Lock your Stacks with Xverse pool to earn up to 10% Bitcoin yield. Xverse pool is a
          trusted and reliable stacking pool service built by the Xverse wallet team. The pool is
          free, non-custodial and has low minimums.
        </LandingBodyText>
        <RedirectButton fontSize={18} marginBottom={48} onClick={() => redirectToStack()}>
          <RowFlex justifyContent={{ s: 'center', md: 'center', lg: 'flex-start' }}>
            Learn more about stacking
            <img
              src={ArrowRight}
              alt="Right Arrow"
              style={{
                marginLeft: 5,
                position: 'relative',
              }}
            />
          </RowFlex>
        </RedirectButton>
        {!isMobile && (
          <StackingStatusView
            stackingStatus={stackingStatus}
            progressPercent={progressPercent}
            currCycleEndsIn={currCycleEndsIn}
            poolCloseIn={poolCloseIn}
            stackingData={stackingData}
          />
        )}
        {!isMobile ? (
          <ButtonContainer>
            <StackingButton
              onClick={() => {
                userData ? navigate('/stacking') : doOpenAuth();
              }}
            >
              <Image src={BitcoinVector} />
              {!userData ||
              stackingStatus.stackingState === StackingState.NotStacking ||
              stackingStatus.stackingState === StackingState.Revoked
                ? 'Start Stacking'
                : 'Go to Dashboard'}
            </StackingButton>
            <TransparentButton onClick={() => redirectToXverseApp()}>
              <Image src={MobileVector} />
              Stack on Xverse mobile
            </TransparentButton>
          </ButtonContainer>
        ) : (
          renderDownloadApp
        )}
      </LandingContainer>
      <CycleInfoCardContainer>
        <CycleInfoCard stackingData={stackingData} />
      </CycleInfoCardContainer>
    </Row>
  );

  const renderStackingStatus = (
    <StackingInfoContainer>
      <HeadingText
        marginTop={{ s: 64, md: 158, lg: 158 }}
        fontSize={{ s: 21, md: 21, lg: 35 }}
        textAlign={{ s: 'start', lg: 'center' }}
      >
        What is Stacking Stacks?
      </HeadingText>
      <LandingBodyText color="grey" textAlign="center" marginTop={{ s: 3, md: 15, lg: 24 }}>
        Stacking is temporarily locking STX tokens to support the Stacks blockchain. Stackers earn
        Bitcoin that Stacks miners transfer to the protocol to mine new STX using a unique Proof of
        Transfer (PoX) consensus mechanism.
      </LandingBodyText>
      <RedirectButton
        fontSize={18}
        marginBottom={48}
        color="orange"
        onClick={() => redirectToStack()}
      >
        <RowFlex justifyContent={{ s: 'center', md: 'center', lg: 'flex-start' }}>
          Learn more about stacking
          <img
            src={OrangeArrowRight}
            alt="Right Arrow"
            style={{
              marginLeft: 5,
              position: 'relative',
            }}
          />
        </RowFlex>
      </RedirectButton>
    </StackingInfoContainer>
  );

  return (
    <Container
      paddingLeft={{ s: 16, md: 120, lg: 120 }}
      paddingRight={{ s: 16, md: 120, lg: 120 }}
      marginLeft={'auto'}
      marginRight={'auto'}
      maxWidth={{ xlg: 1600 }}
    >
      <NewStackingAppBanner />
      {renderContent}
      <Gradient />
      <LowerGradient />
      <CycleStats
        isMobile={isMobile}
        stxLocked={stxLocked}
        nextCycleEndsIn={nextCycleEndsIn}
        totalReward={totalReward}
        currCycleEndsIn={currCycleEndsIn}
        currCycleBegan={currCycleBegan}
        stackingData={stackingData}
      />
      {renderStackingStatus}
      <PoolPerks isMobile={isMobile} />
      <DownloadWallet isMobile={isMobile} />
    </Container>
  );
};
